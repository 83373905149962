<template>
  <v-card :color="tableColor">
    <v-card-title>
      {{ pageTitle }}
    </v-card-title>
    <v-card-text>
      <year-select style="width: 150px" @change="doLoad" />
    </v-card-text>
    <table-v :cols="produtoCols" :opts="opts" :rows="produtos"></table-v>
    <table-v :cols="resumoCols" :opts="opts" :rows="resumo"></table-v>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "table-v": () => import("@/components/table-v.vue"),
    "year-select": () => import("@/components/year-select.vue"),
  },
  computed: {
    ...mapGetters(["clientId", "isClient"]),
    pageTitle: function () {
      return this.$route.meta.name;
    },
    produtoCols: function () {
      return [
        {
          key: "produtoId",
          name: "Código",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "abreviacao",
          name: "Produto",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "incentivo",
          name: "Incentivo",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "jan",
          name: "JAN",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "fev",
          name: "FEV",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "mar",
          name: "MAR",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "abr",
          name: "ABR",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "mai",
          name: "MAI",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "jun",
          name: "JUN",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "jul",
          name: "JUL",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "ago",
          name: "AGO",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "set",
          name: "SET",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "out",
          name: "OUT",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "nov",
          name: "NOV",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "dez",
          name: "DEZ",
          type: this.$fieldTypes.TEXT,
        },
      ];
    },
    resource: function () {
      return this.apiResource(`/v1/faturamento/statuscalculo/${this.clientId}`);
    },
    resumoCols: function () {
      return [
        {
          key: "resumo",
          name: "",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "tri1",
          name: "1º trimestre",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "tri2",
          name: "2º trimestre",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "tri3",
          name: "3º trimestre",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "tri4",
          name: "4º trimestre",
          type: this.$fieldTypes.TEXT,
        },
      ];
    },
  },
  data: function () {
    return {
      produtos: [],
      resumo: [],
      opts: {},
    };
  },
  methods: {
    doLoad: function (anoBase) {
      let query = `anoBase=${anoBase}`;
      this.resource.get({ query }).then((response) => {
        if (!response.error) {
          const meses = [
            "jan",
            "fev",
            "mar",
            "abr",
            "mai",
            "jun",
            "jul",
            "ago",
            "set",
            "out",
            "nov",
            "dez",
          ];
          this.produtos = response.produtos.map(({ status, ...rest }) => {
            return meses.reduce((prev, mes, index) => {
              const key = `${anoBase}-${(index + 1)
                .toString()
                .padStart(2, "0")}`;
              return {
                ...prev,
                [mes]: key in status ? status[key] : "--",
              };
            }, rest);
          });
          const resumo = response.resumo.reduce(
            (prev, curr) => {
              const [key, value] = Object.entries(curr)[0];
              return {
                ...prev,
                [`tri${key}`]: value,
              };
            },
            {
              resumo: "Resumo",
              ["tri1"]: "--",
              ["tri2"]: "--",
              ["tri3"]: "--",
              ["tri4"]: "--",
            }
          );
          console.log({ resumo, responseResumo: response.resumo });
          this.resumo = [resumo];
        } else {
          this.produtos = [];
          this.resumo = [];
        }
      });
    },
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>
